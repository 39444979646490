import {
  Table,
  Tbody,
  Tr,
  Td,
  Icon,
  AbsoluteCenter,
  Divider,
  Flex,
  Box,
  SimpleGrid,
  Text,
  useColorModeValue,
  FormControl,
  Select,
  Button,
} from "@chakra-ui/react";
import React, { useState, useRef } from "react";
import { FaEye, FaTrashAlt, FaUpload } from "react-icons/fa";

import { getToken } from 'helpers/auth';
import Card from "components/card/Card.js";
import { cleanBackground } from 'services';
import { axiosSkodaClient, axiosStrapiClient } from "fetchClient";
import Information from "views/profile/components/Information";


export default function Company(props) {
  const { data, defaultSelectValue, selectHandler, forceUpdate, ...rest } = props;

  const [isLoadingDniFront, setIsLoadingDniFront] = useState(false);
  const [isLoadingDniBack, setIsLoadingDniBack] = useState(false);
  const [isLoadingMandate, setIsLoadingMandate] = useState(false);
  const [isLoadingNif, setIsLoadingNif] = useState(false);

  const [isRemovingDniFront, setIsRemovingDniFront] = useState(false);
  const [isRemovingDniBack, setIsRemovingDniBack] = useState(false);
  const [isRemovingMandate, setIsRemovingMandate] = useState(false);
  const [isRemovingNifFile, setIsRemovingNifFile] = useState(false);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const representative = data.attributes?.representative;

  const handleUploadDniFrontFile = () => {
    dniFrontInputFile.current.click();
  };

  const handleUploadDniBackFile = () => {
    dniBackInputFile.current.click();
  };

  const handleUploadMandateFile = () => {
    mandateInputFile.current.click();
  };

  const handleUploadNifFile = () => {
    nifInputFile.current.click();
  };

  const handleFileChange = async (e) => {
    try {
      if (e.target.files[0]) {
        if (e.target.name === 'dniFront') {
          setIsLoadingDniFront(true);
        } else if (e.target.name === 'dniBack') {
          setIsLoadingDniBack(true);
        } else if (e.target.name === 'nifFile') {
          setIsLoadingNif(true);
        }

        const swipooForm = new FormData();
        swipooForm.append('file', e.target.files[0]);

        let cleanFile = e.target.files[0];

        if (cleanFile.type !== 'application/pdf') {
          const buffer = await cleanBackground(e.target.files[0]);
          cleanFile = new Blob([buffer], {type: "image/png"})
        }

        const strapiForm = new FormData();
        strapiForm.append('files', cleanFile);

        const token = getToken();
        const strapiClient = axiosStrapiClient(token);
        const skodaClient = axiosSkodaClient();

        const [ image ] = await strapiClient.post(`/upload/`, strapiForm).then(res => res.data);

        const ocrResponse = await skodaClient.post(e.target.name === 'nifFile' ? 'tif' : 'doi', {
          uri: image.url,
        }).then(res => res.data);

        if (e.target.name === 'nifFile') {
          await strapiClient.put(`/companies/${data.id}`, {
            data: {
              nif: ocrResponse?.nif,
              legalName: ocrResponse?.legalName,
              fiscalAddressZipCode: ocrResponse?.fiscalAddressZipCode,
              fiscalAddressState: ocrResponse?.fiscalAddressState,
              fiscalAddressProvince: ocrResponse?.fiscalAddressProvince,
              fiscalAddressCity: ocrResponse?.fiscalAddressCity,
              fiscalAddressAddress: ocrResponse?.fiscalAddressAddress,
              nifFile: image.id,
            }
          }).then(res => res.data);
        } else {
          await strapiClient.put(`/people/${representative.data.id}`, {
            data: {
              name: ocrResponse?.name,
              firstSurname: ocrResponse?.firstSurname,
              secondSurname: ocrResponse?.secondSurname,
              dni: ocrResponse?.dni,
              birthDate: ocrResponse?.birthDate,
              dniExpirationDate: ocrResponse?.expiryDate,
              sex: ocrResponse?.sex,
              province: ocrResponse?.province,
              city: ocrResponse?.city,
              zipCode: ocrResponse?.zipCode,
              ccaa: ocrResponse?.ccaa,
              address: ocrResponse?.address,
              [e.target.name]: image.id,
            }
          }).then(res => res.data);
        }
      }
    } finally {
      forceUpdate();
      if (e.target.name === 'dniFront') {
        setIsLoadingDniFront(false);
      } else if (e.target.name === 'dniBack') {
        setIsLoadingDniBack(false);
      } else if (e.target.name === 'nifFile') {
        setIsLoadingNif(true);
      }
    }
  };


  const handleRemoveFile = async (fileName, fileId) => {
    try {
      if (fileName === 'dniFront') {
        setIsRemovingDniFront(true);
      } else if (fileName === 'dniBack') {
        setIsRemovingDniBack(true)
      } else if (fileName === 'mandate') {
        setIsRemovingMandate(true);
      } else if (fileName === 'nifFile') {
        setIsRemovingNifFile(true);
      }
      const token = getToken();
      const client = axiosStrapiClient(token);
      await client.put(`/people/${data.id}`, {
        data: {
          [fileName]: null,
        }
      }).then(res => res.data);
      await client.delete(`/upload/files/${fileId}`).then(res => res.data);
    } finally {
      forceUpdate();
      if (fileName === 'dniFront') {
        setIsRemovingDniFront(false);
      } else if (fileName === 'dniBack') {
        setIsRemovingDniBack(false);
      } else if (fileName === 'mandate') {
        setIsRemovingMandate(false);
      } else if (fileName === 'nifFile') {
        setIsRemovingNifFile(false);
      }
    }
  };

  const handleMandateChange = async (e) => {
    try {
      if (e.target.files[0]) {
        setIsLoadingMandate(true);

        let cleanFile = e.target.files[0];

        if (cleanFile.type !== 'application/pdf') {
          const buffer = await cleanBackground(e.target.files[0]);
          cleanFile = new Blob([buffer], {type: "image/png"})
        }

        const form = new FormData();
        form.append('files',cleanFile);

        const token = getToken();
        const client = axiosStrapiClient(token);

        const [ image ] = await client.post(`/upload/`, form).then(res => res.data);
        await client.put(`/companies/${data.id}`, {
          data: {
            mandate: image.id,
          }
        }).then(res => res.data);
      }
    } finally {
      forceUpdate();
      setIsLoadingMandate(false);
    }
  };

  const mandateInputFile = useRef(null);
  const dniFrontInputFile = useRef(null);
  const dniBackInputFile = useRef(null);
  const nifInputFile = useRef(null);

  return (
    <Card mb={{ base: "20px" }} {...rest}>
      <Flex justifyContent="space-between" mb="20px">
        <Text
          color={textColorPrimary}
          fontWeight='bold'
          fontSize='xl'
          mt='10px'
          mb='4px'>
          {props.title}
        </Text>
        <FormControl width="25%">
          <Select defaultValue={defaultSelectValue} onChange={selectHandler}>
            <option value="person">Particular</option>
            <option value="company">Empresa</option>
          </Select>
        </FormControl>
      </Flex>
      <SimpleGrid columns='3' gap='20px'>
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="companies"
          title='Razón Social'
          value={data.attributes?.legalName}
          inputType="text"
          keyName="legalName"
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="companies"
          title='NIF'
          value={data.attributes?.nif}
          inputType="text"
          keyName="nif"
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="companies"
          title='Dirección'
          value={data.attributes?.fiscalAddressAddress}
          inputType="text"
          keyName="fiscalAddressAddress"
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="companies"
          title='Ciudad'
          value={data.attributes?.fiscalAddressCity}
          inputType="text"
          keyName="fiscalAddressCity"
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="companies"
          title='C. Autónoma'
          value={data.attributes?.fiscalAddressState}
          inputType="text"
          keyName="fiscalAddressState"
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="companies"
          title='Código Postal'
          value={data.attributes?.fiscalAddressZipCode}
          inputType="text"
          keyName="fiscalAddressZipCode"
        />
        </SimpleGrid>
        <Box position='relative' padding='10'>
          <Divider />
          <AbsoluteCenter bg='white' px='4'>
            <Text color="gray.500" fontWeight='500' fontSize='md'>
              Representante
            </Text>
          </AbsoluteCenter>
        </Box>
        <SimpleGrid columns='3' gap='20px'>
        <Information
          id={representative.data.id}
          forceUpdate={forceUpdate}
          type="people"
          title='Nombre'
          value={representative.data.attributes?.name}
          inputType="text"
          keyName="name"
        />
        <Information
          id={representative.data.id}
          forceUpdate={forceUpdate}
          type="people"
          title='Primer Apellido'
          value={representative.data.attributes?.firstSurname}
          inputType="text"
          keyName="firstSurname"
        />
        <Information
          id={representative.data.id}
          forceUpdate={forceUpdate}
          type="people"
          title='Segundo Apellido'
          value={representative.data.attributes?.secondSurname}
          inputType="text"
          keyName="secondSurname"
        />
        <Information
          id={representative.data.id}
          forceUpdate={forceUpdate}
          type="people"
          title='Dirección'
          value={representative.data.attributes?.address}
          inputType="text"
          keyName="address"
        />
        <Information
          id={representative.data.id}
          forceUpdate={forceUpdate}
          type="people"
          title='Ciudad'
          value={representative.data.attributes?.city}
          inputType="text"
          keyName="city"
        />
        <Information
          id={representative.data.id}
          forceUpdate={forceUpdate}
          type="people"
          title='C. Autónoma'
          value={representative.data.attributes?.ccaa}
          inputType="text"
          keyName="ccaa"
        />
        <Information
          id={representative.data.id}
          forceUpdate={forceUpdate}
          type="people"
          title='Código Postal'
          value={representative.data.attributes?.zipCode}
          inputType="text"
          keyName="zipCode"
        />
        <Information
          id={representative.data.id}
          forceUpdate={forceUpdate}
          type="people"
          title='DNI'
          value={representative.data.attributes?.dni}
          inputType="text"
          keyName="dni"
        />
        <Information
          id={representative.data.id}
          forceUpdate={forceUpdate}
          type="people"
          title='Fecha de caducidad'
          value={representative.data.attributes?.dniExpirationDate}
          inputType="date"
          keyName="dniExpirationDate"
        />
        <Information
          id={representative.data.id}
          forceUpdate={forceUpdate}
          type="people"
          title='Fecha de nacimiento'
          value={representative.data.attributes?.birthDate}
          inputType="date"
          keyName="birthDate"
        />
        <Information
          id={representative.data.id}
          forceUpdate={forceUpdate}
          type="people"
          title='Teléfono'
          value={representative.data.attributes?.phone}
          inputType="tel"
          keyName="phone"
        />
        <Information
          id={representative.data.id}
          forceUpdate={forceUpdate}
          type="people"
          title='Correo'
          value={representative.data.attributes?.email}
          inputType="email"
          keyName="email"
        />
      </SimpleGrid>
      <Box position='relative' padding='10'>
          <Divider />
          <AbsoluteCenter bg='white' px='4'>
            <Text color="gray.500" fontWeight='500' fontSize='md'>
              Documentos
            </Text>
          </AbsoluteCenter>
      </Box>
      <Table variant="striped">
        <Tbody>
          <Tr>
            <Td>NIF</Td>
            <Td textAlign="end">
              {data.attributes.nifFile.data ?
                  (isRemovingNifFile ? <Button isLoading loadingText="Eliminando" variant="ghost" height="20px" px="0px" isDisabled />:<>
                    <a href={data.attributes.nifFile.data.attributes.url} target="_blank" rel="noreferrer">
                      <Icon as={FaEye} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" />
                    </a>
                    <Icon as={FaTrashAlt} ml="15px" w='20px' h='20px' color="gray.400" cursor="pointer" onClick={() => handleRemoveFile('nifFile', data.attributes.nifFile.data.id)} />
                  </>)
                : (isLoadingNif ?
                    <Button isLoading loadingText="Cargando" variant="ghost" height="20px" px="0px" isDisabled />
                    :<Icon as={FaUpload} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" onClick={handleUploadNifFile} />
                  )
              }
              <input type="file" name="nifFile" ref={nifInputFile} style={{ display: 'none' }} accept="image/*, application/pdf" onChange={handleFileChange} />
            </Td>
          </Tr>
          <Tr>
            <Td>DNI - Cara</Td>
            <Td textAlign="end">
              {representative.data.attributes.dniFront.data ?
                  (isRemovingDniFront ? <Button isLoading loadingText="Eliminando" variant="ghost" height="20px" px="0px" isDisabled />:<>
                    <a href={representative.data.attributes.dniFront.data.attributes.url} target="_blank" rel="noreferrer">
                      <Icon as={FaEye} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" />
                    </a>
                    <Icon as={FaTrashAlt} ml="15px" w='20px' h='20px' color="gray.400" cursor="pointer" onClick={() => handleRemoveFile('dniFront', representative.data.attributes.dniFront.data.id)} />
                  </>)
                : (isLoadingDniFront ?
                    <Button isLoading loadingText="Cargando" variant="ghost" height="20px" px="0px" isDisabled />
                    :<Icon as={FaUpload} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" onClick={handleUploadDniFrontFile} />
                  )
              }
              <input type="file" name="dniFront" ref={dniFrontInputFile} style={{ display: 'none' }} accept="image/*, application/pdf" onChange={handleFileChange} />
            </Td>
          </Tr>
          <Tr>
            <Td>DNI - Reverso</Td>
               <Td textAlign="end">
              {representative.data.attributes.dniBack.data ? (
                isRemovingDniBack ? <Button isLoading loadingText="Eliminando" variant="ghost" height="20px" px="0px" isDisabled /> :
                <>
                  <a href={representative.data.attributes.dniBack.data.attributes.url} target="_blank" rel="noreferrer"><Icon as={FaEye} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" /></a>
                  <Icon as={FaTrashAlt} ml="15px" w='20px' h='20px' color="gray.400" cursor="pointer" onClick={() => handleRemoveFile('dniBack', representative.data.attributes.dniBack.data.id)} />
                </>)
                : (isLoadingDniBack ? <Button isLoading loadingText="Cargando" variant="ghost" height="20px" px="0px" isDisabled /> : <Icon as={FaUpload} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" onClick={handleUploadDniBackFile} />)
              }
              <input type="file" name="dniBack" ref={dniBackInputFile} style={{ display: 'none' }} accept="image/*, application/pdf" onChange={handleFileChange} />
            </Td>
          </Tr>
          <Tr>
            <Td>Mandato</Td>
               <Td textAlign="end">
              {data.attributes.mandate.data ? (
                isRemovingMandate ? <Button isLoading loadingText="Eliminando" variant="ghost" height="20px" px="0px" isDisabled /> :
                <>
                  <a href={data.attributes.mandate.data.attributes.url} target="_blank" rel="noreferrer"><Icon as={FaEye} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" /></a>
                  <Icon as={FaTrashAlt} ml="15px" w='20px' h='20px' color="gray.400" cursor="pointer" onClick={() => handleRemoveFile('mandate', data.attributes.mandate.data.id)} />
                </>)
                : (isLoadingMandate ? <Button isLoading loadingText="Cargando" variant="ghost" height="20px" px="0px" isDisabled /> : <Icon as={FaUpload} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" onClick={handleUploadMandateFile} />)
              }
              <input type="file" name="mandate" ref={mandateInputFile} style={{ display: 'none' }} accept="image/*, application/pdf" onChange={handleMandateChange} />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Card>
  );
}
